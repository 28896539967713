nav {
    height: 75px;
    width: calc(100vw - 20%);
  
    padding: 0 10%;
  
    background-color: #fff;
    color: black;
  
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
}
  
.nav-left {
    display: flex;
    align-items: center;
}

.nav-left input {
    margin: 0 20px;
    border: none;
    background-color: #C6C6C6;
    height: 30px;
    width: 300px;
    border-radius: 3px;
    padding: 0 10px;
    color: #fff;
}

nav a {
    height: 30px;
    border-radius: 3px;
    padding: 0 10px;
    color: #fff;
    background-color: #0091FF;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.8;
}

.nav-right {
    display: flex;
    align-items: center;
}

nav button {
    font-size: 16px;
    margin-left: 10px;
    height: 30px;
    border-radius: 3px;
    padding: 0 10px;
    color: #fff;
    background-color: #0091FF;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.8;
    border: none;
}

@media screen and (max-width: 600px) {
    nav {
        width: calc(100vw - 10%);
        padding: 0 5%;
    }

    .nav-left input {
        height: 19px;
        width: 80px;
    }

    nav a {
        height: 20px;
        font-size: 14px;
        line-height: 1.4;
        padding: 0 5px;
    }
}
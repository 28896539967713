@import url('https://fonts.googleapis.com/css?family=Muli:300,700&display=swap');

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.profile img {
  margin-right: 10px;
  height: 20px;
}

.profile h3 {
  text-align: center;
  color: black;
}

.profileGenerationsWrapper {
  margin-top: 50px;
  width: 80%;
  padding: 0px 10%;
}

.profileGenerations {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.profileGenerations .generation img {
  width: 200px;
  height: 200px;
}

.profileGenerations .generation .author img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.profileGenerations .generation {
  width: 200px;
  height: 200px;
  padding: 0px 1%;
  margin-bottom: 120px;
}

.profileGenerations .generation button {
  height: 25px;
  border-radius: 3px;
  padding: 0 5px;
  color: #fff;
  background-color: #ff0000;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
}

.generation video {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 600px) {
  .profileGenerations .generation {
    width: 150px;
    height: 150px;
  }

  .profileGenerations .generation img {
    width: 150px;
    height: 150px;
  }
}
.logo {
    color: black;
    cursor: pointer;
}

.logo span {
    background-color: #0091FF;
    color: #fff;
    border-radius: 3px;
    padding: 0px 2px;
    margin-left: 2px;
}

@media screen and (max-width: 600px) {
    .logo {
      font-size: 17px;
    }
}
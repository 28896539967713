.clone {
    display: flex;
    flex-direction: column;
}

.cloneWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 20%);
    height: calc(100vh - 200px);
    padding: 0 10%;
    margin-top: 50px;
    margin-bottom: 75px;
}

#cloneBrushSize {
    width: 20%;
    margin-bottom: 20px;
}

.cloneModalWrapper {
    width: 100vw;
    height: 100vh;
    
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #2727274b;
}

.cloneModal {
    width: 400px;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 5px;
}

.cloneModal img {
    width: 300px;
    margin-bottom: 20px;
}

.cloneButton button {
    height: 50px;
    border-radius: 3px;
    padding: 0 15px;
    color: #fff;
    background-color: #0091FF;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.8;
}

.cloneButton {
    display: flex;
    justify-content: center;

    margin: 25px 0px;
}

.canvasWrapper {
    display: flex;
    justify-content: center;
}

.canvasWrapper img {
    max-height: 100%;
    max-width: 100%;
}

.canvasWrapper video {
    max-height: 100%;
    max-width: 100%;
}

.cloneDropzone {
    margin-top: 25px;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cloneInnerDropzone {
    width: 100%;
    height: calc(100% - 10px);
    background-color: #0091ff47;
    color: #0091FF;

    border: 3px dashed #0091FF;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cloneInnerDropzone svg {
    margin-bottom: 50px;
}
.stripe {
  width: 100%;
  height: 100vh;
}

.stripeContent {
  width: 100%;
  height: calc(100vh - 75px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stripeContent span {
  color: #0091FF;
}

.stripeContent h1 {
  color: black;
  font-size: 72px;
}

.stripeContent button {
  cursor: pointer;
  border: none;
  height: 40px;
  border-radius: 3px;
  padding: 0 10px;
  color: white;
  background-color: #0091FF;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.8;
  margin-top: 50px;
  margin-bottom: 100px;
}
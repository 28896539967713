.generate {
    
}

.generateModalWrapper {
    width: 100vw;
    height: 100vh;
    
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #2727274b;
}

.generateModal {
    width: 400px;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 5px;
}

.generateModal img {
    width: 300px;
    margin-bottom: 20px;
}

.generate .tagsGenerate button {
    height: 50px;
    border-radius: 3px;
    padding: 0 15px;
    color: #fff;
    background-color: #0091FF;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.8;
}

.tagsWrapper {
    display: flex;
    width: calc(100vw - 20%);
    padding: 0 10%;
    margin-top: 50px;
}

.tagsRow {
    width: 50%;
}

.tagsGenerate {
    width: 100vw;

    display: flex;
    justify-content: center;

    margin: 50px 0px;
}
.tags-wrapper h1 {
    color: black;
    font-size: 18px;
    margin-top: 10px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    border: solid 1px #0091FF;
    border-radius: 3px;
    padding: 3px 7px 4px 7px;
    margin: 5px;
    font-size: 14px;
}

.tagSelected {
    border: solid 1px #0091FF;
    background-color: #0091FF;

    color: #27272B;
}
@import url('https://fonts.googleapis.com/css?family=Muli:300,700&display=swap');

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.profile img {
  margin-right: 10px;
  height: 20px;
}

.profile h3 {
  text-align: center;
  color: black;
}

.profileGenerationsWrapper {
  width: 80%;
  padding: 0px 10%;
}

.profileGenerations {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.profileGenerations .generation img {
  width: 200px;
  height: 200px;
}

.profileGenerations .generation .author img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.profileGenerations .generation {
  width: 200px;
  height: 200px;
  padding: 0px 1%;
  margin-bottom: 120px;
}

.folders {
  margin-top: 50px;
}

.folder {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 2px 10px;
  border-radius: 3px;
}

.folder:nth-child(odd) {
  background-color: #f2efef;
}

.folder svg {
  margin-right: 10px;
}

.folder hr {
  height: 2px;
  width: 100%;
  background-color: #0091FF;
  border: none;
  border-radius: 1px;
}

.folderModalWrapper {
  width: 100vw;
  height: 100vh;
  
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #2727274b;
}

.folderModal {
  width: 400px;
  height: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 5px;
}

.modalHeader {
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  height: calc(100% - 150px);
}

.modalFooter {
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .profileGenerations .generation {
    width: 150px;
    height: 150px;
  }

  .profileGenerations .generation img {
    width: 150px;
    height: 150px;
  }
}

.faceSwap {
    display: flex;
    flex-direction: column;
}

.faceSwapWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100vw - 20%);
    height: calc(100vh - 250px);
    padding: 0 10%;
    margin-bottom: 75px;
}

#faceSwapBrushSize {
    width: 20%;
    margin-bottom: 20px;
}

.faceSwapModalWrapper {
    width: 100vw;
    height: 100vh;
    
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #2727274b;
}

.faceSwapModal {
    width: 400px;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 5px;
}

.faceSwapModal img {
    width: 300px;
    margin-bottom: 20px;
}

.faceSwapButton button {
    height: 50px;
    border-radius: 3px;
    padding: 0 15px;
    color: #fff;
    background-color: #0091FF;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.8;
}

.faceSwapButton {
    display: flex;
    justify-content: center;
    width: 100%;

    margin: 25px 0px;
}

.canvasWrapper {
    display: flex;
    justify-content: center;
}

.faceSwapWrapperInner {
    width: 45%;
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.faceSwapWrapperInner h4 {
    margin-bottom: 20px;
}

.faceSwapDropzone {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.faceSwapInnerDropzone {
    width: 100%;
    height: calc(100% - 10px);
    background-color: #0091ff47;
    color: #0091FF;

    border: 3px dashed #0091FF;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faceSwapInnerDropzone svg {
    margin-bottom: 50px;
}
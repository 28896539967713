.login {
    width: 100%;
    height: 100vh;

    background-color: #fff;
    color: #4c4c4c;
}

.login-inner {
    width: 100%;
    height: calc(100vh - 75px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login form {
    width: 600px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 5px;
}

.login form h4 {
    font-size: 50px;
    margin-bottom: 20px;
    color: #0091FF;
}

.login-inner button {
    border: none;
    background-color: #0091FF;
    color: #fff;
    font-weight: 700;
    font-size: 26px;
    border-radius: 10px;
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    width: 80%;
    height: 80px;
    margin-bottom: 20px;
}

.input-group input {
    height: 20px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #33333345;
}

.login-inner button {
    background-color: #0091FF;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

label {
    font-weight: bold;
    font-size: 24px;
}

.login-inner a {
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .login form {
        width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css?family=Muli:300,700&display=swap');

.sub-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.sub-nav h4 {
  margin: 0px 30px;
  color: #4c4c4c;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .sub-nav h4 {
    margin: 0px 15px;
  }
}

.sub-nav .disabled {
  color: #4c4c4c36;
  cursor: default;
}

.btn {
	border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
	color: #fff;
	display: inline-block;
	padding: 5px;
	text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  font-family: 'Muli';
  text-transform: uppercase;
  font-weight: bold;
}

.btn.patreon {
  background-color: rgb(232, 91, 70);
}

.btn.patreon img {
  width: 20px;
  height: 20px;
}

.generations-wrapper {
  width: 80%;
  margin-top: 50px;
  padding: 0px 10%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.generation {
  width: 300px;
  height: 300px;
  padding: 0px 1%;
  margin-bottom: 100px;
}

.generation img {
  width: 300px;
  height: 300px;
}

.generation h4 {
  color: #333;
}

.generation p {
  margin-top: 2px;
}

.generation .author {
  color: #27272B;
  display: flex;
  cursor: pointer;
}

.generation .author:hover {
  color: #fff;
}

.generation .author img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.generation .likes {
  font-size: 14px;
}

.modal-wrapper {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 500px;
  height: 500px;

  background-color: #fff;
}
.logging {
  width: 100vw;
  height: calc(100vh - 75px);

  display: flex;
  justify-content: center;
}

.log-wrapper {
  width: 80%;
  height: fit-content;
  margin: 50px 0px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05), 0 6px 6px rgba(0,0,0,0.1);
}

.log {
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 20px;
}

.log p:nth-child(1) {
  width: 10%;
}

.log p:nth-child(2) {
  width: 10%;
}

.log p:nth-child(3) {
  width: 10%;
}

.log p:nth-child(4) {
  width: 60%;
}
.log p:nth-child(5) {
  width: 10%;
}